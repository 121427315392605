import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ProductPage } from '~/components/ProductPage/ProductPage';
import { IProduct } from '~/types/types';

const ProductTemplate: React.FC<ProductPageProps> = ({ data }) => (
  <Layout>
    <Metadata
      title={data.product.name}
      description={data.product.shortDescription}
    />
    <ProductPage product={data.product} />
  </Layout>
);

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      __typename
      ...ProductPageFragment
    }
  }
`;

export interface ProductPageProps {
  data: {
    product: { __typename: string } & IProduct;
  };
}

export default ProductTemplate;
